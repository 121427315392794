/* ==========================================================================
	 Breadcrumb
	 ========================================================================== */

.Breadcrumb, .BreadcrumbBlock {
    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }
}
