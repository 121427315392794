// ==========================================================================
// Typography
// ==========================================================================

body,
html {
	font-size: $font-size-base;
}

body {
	text-align: left;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

h1, h2, h3, h4, h5, h6 {
	line-height: $line-height-heading;
	@include space-stack("l");
	font-weight: bold;
}
.display-1 {
	@include font-size(9);
	font-weight: bold;
}
.display-2 {
	@include font-size(8);
	font-weight: bold;
}
.display-3 {
	@include font-size(7);
	font-weight: bold;
}
.display-4 {
	@include font-size(6);
	font-weight: bold;
}

h1 {
	@include font-size(6);
	font-weight: bold;
}

h2 {
	@include font-size(5);
}

h3 {
	@include font-size(4);
	font-weight: bold;
}

h4 {
	@include font-size(3);
	font-weight: bold;
}

h5  {
	@include font-size(2);
	font-weight: bold;
}

h6 {
	@include font-size(1);
	font-weight: bold;
}
/////////////////////////////////////////
////////////// Paragraphs ///////////////
/////////////////////////////////////////

p {
	line-height: $line-height-copy;
	@include space-stack(m);
}

.copy {
	&, p {
		max-width: 45em;
	}
}
