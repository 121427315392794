/* ==========================================================================
	 Table
	 ========================================================================== */

.Table {
	width: 100%;
	margin-bottom: 20px;

	tr {

		th {
			padding: 8px;
			line-height: 1.42857143;
			vertical-align: top;
			border-top: 1px solid color(primary);
			text-align: left;
		}

		td {
			padding: 8px;
			border-top: 1px solid color(primary);
		}

	}

}
