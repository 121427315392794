/* ==========================================================================
	 Image
	 ========================================================================== */

img,
.Image {
	display: block;
	margin: 0;
		padding: 0;

	&.Image--responsive {
		width: 100%;
		height:auto;
	}
}
figure {
	margin: 0 0 1rem;
}
