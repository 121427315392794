/* ==========================================================================
	 Spacing (< Mixins < Tools)
	 ========================================================================== */

 /**
	 space-inset()

	 Sets responsive inset (uniform padding) spacing. It will set the requested
	 value for the desktop breakpoints but scale it down for tablet and mobile

	 @param {string} $space-value value as specified in variables/$space-values
	 @return {null}
 */
 @mixin space-inset($space-value, $responsive: true) {
	 $index: index(map-keys($space-values), $space-value);
	 $base-index: 1;
	 $base-key: nth(map-keys($space-values), $base-index);

	 @if($responsive) {
		 @if map-has-key($space-values, $space-value) {
			 @if $index > $base-index { // if greater than base size, scale responsively
				 padding: map-get($space-values, if($index - 1 > $base-index, nth(map-keys($space-values), $index - 1), $base-key));

				 @include above(break(xl)) {
					 padding: map-get($space-values, $space-value);
				 }
			 } @else { // if base size or smaller, leave as is
				 padding: map-get($space-values, $space-value);
			 }
		 }
	 } @else {
		 padding: map-get($space-values, $space-value);
	 }
 }

 /**
	 space-inset-squish()

	 Sets responsive inset squish (horizontal padding larger than vertical
	 padding) spacing.

	 @param {string} $space-value value as specified in variables/$space-values
	 @return {null}
 */
 @mixin space-inset-squish($space-value, $responsive: true, $factor: 2) {
	 $index: index(map-keys($space-values), $space-value);
	 $base-index: 1;
	 $base-key: nth(map-keys($space-values), $base-index);

	 @if($responsive) {
		 @if map-has-key($space-values, $space-value) {
			 @if $index > $base-index { // if greater than base size, scale responsively
				 $value: map-get($space-values, if($index - 2 > $base-index, nth(map-keys($space-values), $index - 2), $base-key));
				 padding: $value/$factor $value;

				 @include above(break(m)) {
					 $value: map-get($space-values, if($index - 1 > $base-index, nth(map-keys($space-values), $index - 1), $base-key));
						 padding: $value/$factor $value;
				 }

				 @include above(break(xl)) {
					 $value: map-get($space-values, $space-value);
					 padding: $value/$factor $value;
				 }
			 } @else { // if base size or smaller, leave as is
				 $value: map-get($space-values, $space-value);
				 padding: $value/$factor $value;
			 }
		 }
	 } @else {
		 $value: map-get($space-values, $space-value);
		 padding: $value/$factor $value;
	 }
 }

 /**
	 space-inset-stretch()

	 Sets responsive inset stretch (vertical padding larger than horizontal
	 padding) spacing.

	 @param {string} $space-value value as specified in variables/$space-values
	 @return {null}
 */
 @mixin space-inset-stretch($space-value, $responsive: true, $factor: 1.5) {
	 $index: index(map-keys($space-values), $space-value);
	 $base-index: 1;
	 $base-key: nth(map-keys($space-values), $base-index);

	 @if($responsive) {
		 @if map-has-key($space-values, $space-value) {
			 @if $index > $base-index { // if greater than base size, scale responsively
				 $value: map-get($space-values, if($index - 2 > $base-index, nth(map-keys($space-values), $index - 2), $base-key));
				 padding: $value*$factor $value;

				 @include above(break(m)) {
					 $value: map-get($space-values, if($index - 1 > $base-index, nth(map-keys($space-values), $index - 1), $base-key));
					 padding: $value*$factor $value;
				 }

				 @include above(break(xl)) {
					 $value: map-get($space-values, $space-value);
					 padding: $value*$factor $value;
				 }
			 } @else { // if base size or smaller, leave as is
				 $value: map-get($space-values, $space-value);
				 padding: $value*$factor $value;
			 }
		 }
	 } @else {
		 $value: map-get($space-values, $space-value);
		 padding: $value*$factor $value;
	 }
 }


 /**
	 space-stack()

	 Sets responsive stack (margin bottom) spacing. It will set the requested
	 value for the desktop breakpoints but scale it down for tablet and mobile

	 @param {string} $space-value value as specified in variables/$space-values
	 @return {null}
	*/
 @mixin space-stack($space-value, $responsive: true, $zero-last-child: true) {
	 $index: index(map-keys($space-values), $space-value);
	 $base-index: 1;
	 $base-key: nth(map-keys($space-values), $base-index);

	 @if($responsive) {
		 @if map-has-key($space-values, $space-value) {
			 @if $index > $base-index { // if greater than base size, scale responsively
				 margin: 0 0 map-get($space-values, if($index - 1 > $base-index, nth(map-keys($space-values), $index - 1), $base-key)) 0;

				 @include above(break(xl)) {
					 margin: 0 0 map-get($space-values, $space-value) 0;
				 }
			 } @else { // if base size or smaller, leave as is
				 margin: 0 0 map-get($space-values, $space-value) 0;
			 }
		 }
	 } @else {
		 margin: 0 0 map-get($space-values, $space-value) 0;
	 }

	 @if($zero-last-child) {
		 @include reset-child("last", "margin", 0);
	 }
 }

 /**
	 space-inline()

	 Sets responsive inline (margin right) spacing. It will set the requested
	 value for the desktop breakpoints but scale it down for tablet and mobile.

	 @param {string} $space-value value as specified in variables/$space-values
	 @return {null}
 */
 @mixin space-inline($space-value, $responsive: true, $zero-last-child: true) {
	 $index: index(map-keys($space-values), $space-value);
	 $base-index: 1;
	 $base-key: nth(map-keys($space-values), $base-index);

	 @if($responsive) {
		 @if map-has-key($space-values, $space-value) {
			 @if $index > $base-index { // if greater than base size, scale responsively
				 margin: 0 map-get($space-values, if($index - 1 > $base-index, nth(map-keys($space-values), $index - 1), $base-key)) 0 0;

				 @include above(break(s)) {
					 margin: 0 map-get($space-values, $space-value) 0 0;
				 }
			 } @else { // if base size or smaller, leave as is
				 margin: 0 map-get($space-values, $space-value) 0 0;
			 }
		 }
	 } @else {
		 margin: 0 map-get($space-values, $space-value) 0 0;
	 }

	 @if($zero-last-child) {
		 @include reset-child("last", "margin", 0);
	 }
 }
