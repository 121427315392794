// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: true !default;
$enable-shadows: true;
$enable-gradients: true;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Variables
//
// Colors
$theme-colors: (
  
  // Main Colours
  'primary': #00428c,     // Italmatch Blue
  'secondary': #092237,   // Italmatch Darker Blue
  'gold': #D2B118,        // Italmatch Gold
	'purple': #A760A8,       // Italmatch Purple
  
  // Supporting Colours
  'light': #f4f5f7,
  'info': #F0F0F0,
	'grey': #DBE0E6,
  'dark': #3d3d3e,
  'success': #a3ca60,
  'warning': #ffc107,
  'danger': #dc3545,
);
$body-bg: white;
$body-color: #5b5b5d;
$body-color-inverse: invert($body-color) !default;
$link-color: #00428c;
$enable-gradients: false;
$enable-shadows: false;

// Fonts
$font-family-base: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$headings-font-family: 'Tajawal', -apple-system;

$lead-font-size: 1.30rem;
$spacer: 1.5rem;
$enable-responsive-font-sizes: true;
$border-radius: 8px;
$carousel-indicator-width: 100px;
$carousel-indicator-height: 10px;

@import "~bootstrap/scss/bootstrap";