/* ==========================================================================
	 Aspect ratio (< Mixins < Tools)
	 ========================================================================== */

 @mixin aspect-ratio($aspect-ratio: 1, $responsive: false) {

	position: relative;

	&:before {
		content: "";
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
	}

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	 &:before {
		 @if($responsive) {
			 @include above(break(s)) {
				 padding-top: 1/$aspect-ratio * 100%;
			 }

			 @if($aspect-ratio < 2) {
				 @include between(break(m), break(xl)) {
					 padding-top: 1/2 * 100%;
				 }
			 }
		 } @else {
			 padding-top: 1/$aspect-ratio * 100%;
		 }
	 }

	 @if($responsive) {
		 @include below(break(m)) {
			 &:before {
				 display: none;
			 }
		 }
	 }
 }
