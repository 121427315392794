/* ==========================================================================
	 List
	 ========================================================================== */

dl,
dt,
dd,
menu,
ol,
ul {

    &.List {
        margin-left: 2em;

        &, li {
            list-style-type: disc;
        }

        li {
            line-height: 1.5;
            @include space-stack(s);


        }
    }


}

.text-center {
    ol,
ul {
        list-style: none;
    }
}
