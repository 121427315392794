/* ==========================================================================
	 Button
	 ========================================================================== */

$button-radius: 5px;
$button-color: color(primary);
$button-primary-color: color(primary);
$button-inverse-color: color(white);


.btn {
	padding: 12px 24px 12px 24px;
    text-transform: capitalize;
    border-radius: 999px;
    font-size: 1em;
    font-weight: 600;
}

.Button {

	@include link-color(color(white), false);
	@include space-inline(m);
	@include space-inset-squish(l, false);

	&:hover {
	}

	&:visited {
	}

	&.Button--primary {
		background-color: $button-primary-color;

		&:hover {
			background-color: darken(color(primary), 5%);
		}
	}

	// Utilities
	&.Button--large {
		font-size: font-size(0);
		@include space-inline(2xl);
		@include space-inset-squish(xl, false);
	}

	&.Button--small {
		font-size: font-size(-2);
		@include space-inset-squish(s, false);
	}

	&.Button--disabled, button[disabled] {
		cursor: not-allowed;
		opacity: 0.5;
	}

}
