/* ==========================================================================
	 Back To Top
	 ========================================================================== */

.BackToTop {
		display: inline-block;
		height: 40px;
		width: 40px;
		position: fixed;
		bottom: 15px;
		right: 15px;
		z-index: 10000;
		box-shadow: 0 0 10px rgba(#000, .05);
		border: 1px solid #99a4ab;
		border-radius: 50%;
		/* image replacement properties */
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
			background: color(secondary);
		visibility: hidden;
		opacity: 0;
		-webkit-transition: opacity .3s 0s, visibility 0s .3s;
		-moz-transition: opacity .3s 0s, visibility 0s .3s;
		transition: opacity .3s 0s, visibility 0s .3s;
		&.cd-is-visible,
		&.cd-fade-out,
		.no-touch &:hover {
				-webkit-transition: opacity .3s 0s, visibility 0s 0s;
				-moz-transition: opacity .3s 0s, visibility 0s 0s;
				transition: opacity .3s 0s, visibility 0s 0s;
		}
		&.cd-is-visible {
				/* the button becomes visible */
				visibility: visible;
				opacity: .7;
		}
		&.cd-fade-out {
				/* if the user keeps scrolling down, the button is out of focus and becomes less visible */
				opacity: .5;
		}
		.no-touch &:hover {
				opacity: 1;
		}

		.Icon {
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;
			height: 100%;
			transform: scale(.5);
			color: color(primary);

		}
		@media only screen and (min-width: 768px) {
				.cd-top {
						right: 20px;
						bottom: 20px;
				}
		}
		@media only screen and (min-width: 1024px) {
				.cd-top {
						height: 60px;
						width: 60px;
						right: 30px;
						bottom: 30px;
				}
		}
}
