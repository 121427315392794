/* SITE SET UP */


.noBodyScroll {
    overflow: hidden;
}

label {
    font-weight: 300;
}

input, textarea, select, a {
    outline: none;
}

/*select {-webkit-appearance: none; -webkit-border-radius: 0px;}*/

/* LAZY LOAD */
.b-lazy {
    -webkit-transition: opacity 500ms ease-in-out;
    -moz-transition: opacity 500ms ease-in-out;
    -o-transition: opacity 500ms ease-in-out;
    transition: opacity 500ms ease-in-out;
    max-width: 100%;
    opacity: 0;
}

.b-lazy.b-loaded {
    opacity: 1;
    background-size: cover;
}

/*#loadCalendar iframe html {overflow-y: auto !important;}
*/
/* JQUERY UI OVERRIDES */
.ui-accordion .ui-accordion-content {
    padding: 0px 20px !important;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    border: none;
    background: #eee;
    color: inherit;
}

.ui-state-focus:focus {
    outline: none;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-br, .ui-corner-right, .ui-corner-left, .ui-corner-tl, .ui-corner-top {
    border-radius: 0;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    left: 23px !important;
}

.slick-slide {
    outline: none !important;
}

.setMeFree {
    clear: both;
}

.centerMe {
    text-align: center;
}

.showOnDesktop {
    display: none
}

.showOnMobile {
    display: block;
}

.hoverMe:hover {
    cursor: pointer;
}

.underlineMe {
    text-decoration: underline;
}

.underlineMe:hover {
    text-decoration: none;
}

.noPadding {
    padding: 0 !important;
}

.padding10 {
    padding: 10px;
}

.padding0510 {
    padding: 5px 8px 3px 10px;
}

.padding20 {
    padding: 20px;
}

.padding30 {
    padding: 30px;
}

.padding40 {
    padding: 40px;
}

.padding2030 {
    padding: 20px 30px;
}

.paddingTop20 {
    padding-top: 20px !important;
}

.paddingTop10 {
    padding-top: 10px;
}

.paddingTop15 {
    padding-top: 15px;
}

.paddingTopBottom15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.paddingTopBottom20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.paddingTopRightBottom30 {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
}

.paddingTopRightLeft20 {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
}

.paddingRightLeft20 {
    padding-right: 20px;
    padding-left: 20px;
}

.paddingLeft15 {
    padding-left: 15px;
}

.paddingLeft27 {
    padding-left: 27px;
}

.paddingLeft30 {
    padding-left: 30px;
}

.paddingLeft32 {
    padding-left: 32px;
}

.paddingTop0 {
    padding-top: 0px;
}

.paddingTop20 {
    padding-top: 20px;
}

.paddingTop27 {
    padding-top: 27px;
}

.paddingBottom27 {
    padding-bottom: 27px;
}

.paddingTop30 {
    padding-top: 30px;
}

.paddingTop40 {
    padding-top: 40px;
}

.paddingTopBottom20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.paddingTopBottom40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.paddingTopBottom50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.paddingTopBottom60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.paddingTop50 {
    padding-top: 50px;
}

.paddingTop60 {
    padding-top: 60px;
}

.paddingBottom15 {
    padding-bottom: 15px;
}

.paddingBottom20 {
    padding-bottom: 20px;
}

.paddingBottom30 {
    padding-bottom: 30px;
}

.paddingBottom40 {
    padding-bottom: 40px;
}

.paddingBottom50 {
    padding-bottom: 50px;
}

.noMargin {
    margin: 0 !important;
}

.marginTop80 {
    margin-top: 80px;
}

.marginTop15 {
    margin-top: 15px;
}

.marginTopBottom50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.marginTop20 {
    margin-top: 20px;
}

.marginTop30 {
    margin-top: 30px;
}

.marginTop40 {
    margin-top: 40px;
}

.marginRight15 {
    margin-right: 15px;
}

.marginRight20 {
    margin-right: 20px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginLeft30 {
    margin-left: 30px;
}

.marginBottom30 {
    margin-bottom: 30px;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.marginBottom10 {
    margin-bottom: 10px;
}

.marginBottom15 {
    margin-bottom: 15px;
}

.marginTop124 {
    margin-top: 80px;
}

.absBottomPosition {
    position: absolute;
    bottom: 20px;
}

.posLeft {
    left: 0px;
}

.posRight {
    right: 0px;
}

.normalWeight {
    font-weight: 300;
}

.upperCase {
    text-transform: uppercase;
}

.capitals {
    text-transform: capitalize;
}

a, .trans05 {
    transition: all .25s;
}


.floatInline {
    display: inline-block;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.borderBottom {
    border-bottom: 1px solid #eee;
}

.borderLine {
    width: 100%;
    height: 1px;
    margin-top: -10px;
}


figcaption {
    font-size: 0.8em;
}

b, strong {
    font-weight: 600;
}

*, *:before, *:after {
    box-sizing: border-box;
}

.pageContent img {
    margin-bottom: 15px;
}

table td {
    min-width: 115px;
}

table img {
    margin-bottom: 20px;
    margin-right: 20px;
    max-width: 130px !important;
}

.tableImages {
    width: 130px;
}

.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
    font-weight: 500;
}

input {
    border-radius: 0;
}

input, textarea {
    border: none;
    padding: 5px;
}

a:focus, a:hover /* input:focus*/
{
    outline: none;
    color: color(purple);
    text-decoration: none;
}

#pageCover {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1045;
    display: none;
}

/*iframe {
	transform: scale(0.8) !important;
	-webkit-transform:scale(0.8) !important;
	transform-origin:0 0;
	-webkit-transform-origin:0 0;
}*/

.captchaHolder {
    width: 100% !important;
}

.contactRight {
    position: relative;
}

.contactRight iframe {
    position: relative;
    right: 0px;
    top: 0px;
}

iframe .rc-anchor-normal .rc-anchor-pt {
    right: 41px !important;
}

.iframeHolder iframe {
    transform: scale(1) !important;
    -webkit-transform: scale(1) !important;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}

/* COLOURS */
.whiteText {
    color: #fff;
}

.blackText {
    color: #000;
}

.greyText {
    color: #7c7d81;
}

.darkText {
    color: color(dark);
}

.lightText {
    color: color(light);
}

.lightGreyText {
    color: color(grey);
}

.blueText {
    color: color(primary);
}

.primaryText {
    color: color(primary);
}

.secondaryText {
    color: color(primary);
}

.purpleText {
    color: color(purple);
}

.goldText {
    color: color(gold);
}

.decommissioningText {
    color: color(primary);
}

.renewablesText {
    color: color(primary);
}

.whiteBg {
    background: #fff;
}

.blueBg {
    background: color(primary)
}

.primaryBg {
    background: color(primary)
}

.secondaryBg {
    background: color(secondary)
}

.purpleBg {
    background: color(purple)
}

.goldBg {
    background: color(gold);
}



.decommissioningBg {
    background: color(primary);
}

.renewablesBg {
    background: color(primary);
}

.lightGreyBg {
    background: #f4f5f7;
}

.greyBg {
    background: #7c7d81;
}

.darkBg {
    background: rgba(0, 0, 0, 0.2)
}

.blueBorder {
    border-bottom: 1px solid color(primary);
}

.purpleBorder {
    border-bottom: 1px solid color(purple);
}

.goldBorder {
    border-bottom: 1px solid color(gold);
}

.greyBorder {
    border-bottom: 1px solid #7c7d81;
}

.decommissioningBorder {

        border-bottom: 1px solid color(primary);

}

.renewablesBorder {
     border-bottom: 1px solid color(primary);
}



.lightGreyBorder {
    border-bottom: 1px solid #f4f5f7;
}

.greyBorderTop {
    border-top: 1px solid #7c7d81;
}

.mainImageGrey {
    background: #b8b9b9;
}

.borderBottomGrey {
    border-bottom: 1px solid #bbb;
}

.twitterBg {
    background: #55acee;
}

.facebookBg {
    background: #3b5998;
}

.darkGreyText:hover {
    text-decoration: none;
    color: inherit;
}

.latestLink {
    border-radius: 10px;
    animation: colorchange 5s infinite alternate forwards;
    -webkit-animation: colorchange 5s infinite alternate forwards;
}

.latestLinkLi a:hover, .latestLinkLi:hover, .latestLinkLi a:focus, .latestLinkLi:focus {
    color: #fff !important;
}

@keyframes colorchange {
    0% {
        background: color(secondary);
    }
    25% {
        background: color(purple);
    }
    50% {
        background: color(gold);
    }
    75% {
        background: color(primary);
    }
    100% {
        background: color(primary);
    }
}

@-webkit-keyframes colorchange {
    0% {
        background: color(secondary);
    }
    25% {
        background: color(purple);
    }
    50% {
        background: color(gold);
    }
    75% {
        background: color(primary);
    }
    100% {
        background: color(primary);
    }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bbb;
    font-weight: 600;
    font-size: 0.9em;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #bbb;
    font-weight: 600;
    font-size: 0.9em;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #bbb;
    font-weight: 600;
    font-size: 0.9em;
}

.form-error {
    background: #e51837;
    color: #fff;
    line-height: 180%;
    margin-bottom: 22px;
    padding: 6px 12px;
    border-radius: 0;
    border: none;
}

select {
    min-width: 50%;
    max-width: 100%;
}

.radioForm {
    float: left;
    padding-top: 10px;
    width: 58%;
}

.radioForm label {
    margin-right: 20px;
    font-weight: 300;
}

/* BUTTONS */
.theButtons {
    padding: 8px 16px;
    //text-transform: uppercase;
    display: inline-block;
    border-radius: 999px;
    font-size: 0.9em;
    font-weight: 600;
    text-transform: capitalize;
}

.lightButtons:hover {
    background: color(secondary);
    color: #fff;
    text-decoration: none !important;
}

.darkButtons:hover {
    background: color(secondary);
    color: #f4f5f7;
    text-decoration: none !important;
}

.noTextDeco {
    text-decoration: none !important;
}

.mobileButtons {
    width: 100%;
}

.arrowPrev img, .arrowNext img, .newsSliderPrev img, .newsSliderNext img {
    width: 10px;
    opacity: 0.25;
    -webkit-transition: .5s;
    transition: .5s;
}

.arrowPrev:hover img, .arrowNext:hover img, .newsSliderPrev:hover img, .newsSliderNext:hover img {
    cursor: pointer;
    opacity: 1;
}

.arrowButtons {
    opacity: 0.4;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.arrowHolder {
    padding: 10px 0px 0px 0px;
}

.arrowHolder .arrowButtons {
    opacity: 1;
}

.arrowHolder .arrowButtons img {
    width: 20px;
    height: 20px;
}

.arrowButtons img {
    width: 30px;
    height: 30px;
}

.arrowPrev {
    margin-left: -20px;
    position: absolute;
    top: 44%;
    display: none;
}

.arrowNext {
    right: -20px;
    position: absolute;
    top: 44%;
    display: none;
}

.mobileArrowHolder {
    width: 40px;
    margin: 0 auto;
}

.arrowPrev img, .arrowNext img, .newsSliderPrev img, .newsSliderNext img {
    width: 10px;
    opacity: 0.25;
    -webkit-transition: .5s;
    transition: .5s;
}

.mobileArrowPrev {
    float: left;
}

.mobileArrowNext {
    float: right;
}

.mobileArrowPrev img, .mobileArrowNext img {
    width: 18px;
    opacity: 0.25;
    -webkit-transition: .5s;
    transition: .5s;
}

/* NAV BAR */

.mainNav {
    display: none;
}

.navbar-default {
    border: none;
    //height: 80px;
    margin-bottom: 0px;
    background: none;
    box-sizing: content-box;
}

.navBarBorder {
    border-bottom: 1px solid #dedede;
}

.navbar-fixed-bottom, .navbar-fixed-top {
    position: absolute;
}

.navbar-absolute {
    position: absolute
}


.sectionPos {
    position: relative;
    z-index: 3;
}

#aubinLines {
    position: absolute;
    width: 100%;
    height: 715px;
    background-image: url('https://aubin.s3-eu-west-1.amazonaws.com/uploads/Images/aubinLines.svg');
    background-size: 4460px;
    background-position: -1427px 59px;
    background-repeat: no-repeat;
}

/* DESKTOP SEARCH */
.desktopSearch {
    border-radius: 10px 0px 0px 10px;
    padding: 5px 0px 5px 10px;
}

#searchForm {
    margin-top: 10px;
}

.searchButton {
    border-radius: 0px 10px 10px 0px;
    padding: 6px 9px 5px 10px;
    color: color(grey);
}

.searchButton:hover {
    background: color(purple);
    cursor: pointer;
    color: color(white);
}

#searchFormMobile #search {
    width: 305px;
    border-radius: 10px 0px 0px 10px;
    padding: 9px 10px 8px 10px;
}

#searchFormMobile .searchButton {
    padding: 9px 13px 8px 10px;
    color: #fff;
}

#searchFormMobile #search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
}

#searchFormMobile #search:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}

#searchFormMobile #search::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
}

/* MOBILE NAV */

.nosubLink {
    padding: 7px 11px 6px 0px !important
}

.followUs {
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 11px;
}

.rightContent {
    margin-bottom: 20px;
}

#mobileSearchForm {
    display: block;
}

#mobileSearch {
    width: calc(100% - 31px);
}

.cd-accordion-menu .nosubLink {
    padding: 7px 11px 6px 7px !important;
}

.cd-accordion-menu {
    width: calc(100% - 30px);
    max-width: 600px;
    margin: 15px 15px 0px 15px;
    padding: 0;

}

.cd-accordion-menu ul {
    display: none;
}

.cd-accordion-menu li {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    list-style: none;
    color: #1e2945;
    margin-top: 10px;
    padding: 5px;
    border-radius: 12px;
}

.cd-accordion-menu input[type=checkbox] {
    position: absolute;
    opacity: 0;
}

.cd-accordion-menu label {
    position: relative;
    display: block;
    padding: 9px 18px 6px 26px;
    font-weight: 300;
    margin-bottom: 0px;
    //text-transform: uppercase;
}

.cd-accordion-menu a {
    position: relative;
    display: block;
    padding: 0px 18px 6px 40px;
    font-weight: 300;
    margin-bottom: 0px;
    //text-transform: uppercase;

}

.no-touch .cd-accordion-menu label:hover, .no-touch .cd-accordion-menu a:hover {
    background: #52565d;
}

.cd-accordion-menu label::before, .cd-accordion-menu label::after, .cd-accordion-menu a::after {
    /* icons */
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cd-accordion-menu ul label::before, .cd-accordion-menu ul label::after, .cd-accordion-menu ul a::after {
    top: 50%;
    -webkit-transform: translateY(-43%);
    -moz-transform: translateY(-43%);
    -ms-transform: translateY(-43%);
    -o-transform: translateY(-43%);
    transform: translateY(-43%);
}

.cd-accordion-menu label {
    cursor: pointer;
}

.cd-accordion-menu label::before, .cd-accordion-menu label::after {
    background-image: url(../img/plus.svg);
    background-repeat: no-repeat;
}

.cd-accordion-menu label::before {
    /* arrow icon */
    left: 0px;
    background-position: 0px;
    -webkit-transform: translateY(-45%) rotate(-90deg);
    -moz-transform: translateY(-45%) rotate(-90deg);
    -ms-transform: translateY(-45%) rotate(-90deg);
    -o-transform: translateY(-45%) rotate(-90deg);
    transform: translateY(-45%) rotate(-90deg);
}

.cd-accordion-menu li ul {
    padding: 0px 0px 0px 30px;
}

.cd-accordion-menu li ul li ul {
    padding: 0px 0px 0px 15px;
}

.cd-accordion-menu ul label::before {
    /* arrow icon */
    left: 18px;
    background-position: 0px 0px;
    -webkit-transform: translateY(-50%) rotate(-90deg);
    -moz-transform: translateY(-50%) rotate(-90deg);
    -ms-transform: translateY(-50%) rotate(-90deg);
    -o-transform: translateY(-50%) rotate(-90deg);
    transform: translateY(-50%) rotate(-90deg);
}

.cd-accordion-menu label::after {
    /* folder icons */
    left: 41px;
    background-position: -16px 0;
}

.cd-accordion-menu a::after {
    /* image icon */
    left: 36px;
    background: url(../images/arrowDown.svg) no-repeat -48px 0;
}

.cd-accordion-menu input[type=checkbox]:checked + label::before {
    /* rotate arrow */
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cd-accordion-menu input[type=checkbox]:checked + label::after {
    /* show open folder icon if item is checked */
    background-position: -32px 0;
}

.cd-accordion-menu input[type=checkbox]:checked + label + ul,
.cd-accordion-menu input[type=checkbox]:checked + label:nth-of-type(n) + ul {
    /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
    /* show children when item is checked */
    display: block;
}

.cd-accordion-menu ul label,
.cd-accordion-menu ul a {
    padding-left: 5px;
}

.no-touch .cd-accordion-menu ul label:hover, .no-touch
.cd-accordion-menu ul a:hover {
    background: #3c3f45;
}

.cd-accordion-menu > li:last-of-type > label,
.cd-accordion-menu > li:last-of-type > a,
.cd-accordion-menu > li > ul > li:last-of-type label,
.cd-accordion-menu > li > ul > li:last-of-type a {
    box-shadow: none;
}

.cd-accordion-menu ul label::before {
    left: 0px;
}

.cd-accordion-menu ul label::after,
.cd-accordion-menu ul a::after {
    left: 59px;
}

.cd-accordion-menu ul ul label,
.cd-accordion-menu ul ul a {
    padding-left: 0px;
}

.cd-accordion-menu ul ul label::before {
    left: -21px;
}

.cd-accordion-menu ul ul label::after,
.cd-accordion-menu ul ul a::after {
    left: 77px;
}

.cd-accordion-menu ul ul ul label,
.cd-accordion-menu ul ul ul a {
    padding-left: 0px;
}

.cd-accordion-menu ul ul ul label::before {
    left: 72px;
}

.cd-accordion-menu li ul li {
    margin: 0;
}

.cd-accordion-menu li ul li a {
    padding: 0px;
}

.cd-accordion-menu li ul li ul li a {
    padding: 5px 10px 5px 0px;
}

.cd-accordion-menu li ul li ul li ul {
    padding: 0px;
    margin: 0;
}

.cd-accordion-menu li ul label {
    padding: 8px 0px 2px 23px;
}

.cd-accordion-menu li ul li ul label {
    padding: 5px 18px 5px 0px;
}

.cd-accordion-menu ul ul ul label::after,
.cd-accordion-menu ul ul ul a::after {
    left: 95px;
}

.cd-accordion-menu.animated label::before {
    /* this class is used if you're using jquery to animate the accordion */
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
}

/* INTERNAL NAV */

.cd-accordion-menu-internal {
    width: 100%;
    margin: 0px 0px 12px 0px;
    padding: 0;
    //text-transform: uppercase;

}

.cd-accordion-menu-internal a:hover {
    /*color: #fff;
    text-decoration: none;*/
}

.cd-accordion-menu-internal ul {
    display: none;
}

.cd-accordion-menu-internal li ul {
    margin: 0;
    padding: 0px 0px 0px 10px;
}

.cd-accordion-menu-internal li ul li ul {
    list-style: none;
}

.cd-accordion-menu-internal li {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    list-style: none !important;
    color: #b8b6aa;
    margin-top: 0px;
    border-top: 1px solid #eee;
}

.cd-accordion-menu-internal input[type=checkbox] {
    /* hide native checkbox */
    position: absolute;
    opacity: 0;
}

.cd-accordion-menu-internal label, .cd-accordion-menu-internal a {
    position: relative;
    display: block;
    padding: 5px 11px 0px 0px;
    font-weight: 300;
    margin-bottom: 0px;
}

.no-touch .cd-accordion-menu-internal label:hover, .no-touch .cd-accordion-menu-internal a:hover {
    background: #52565d;
}

.cd-accordion-menu-internal label::before, .cd-accordion-menu-internal label::after, .cd-accordion-menu-internal a::after {
    /* icons */
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 42%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cd-accordion-menu-internal ul li label::before, .cd-accordion-menu-internal ul li label::after, .cd-accordion-menu-internal ul li a::after {
    /* icons */
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 28%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cd-accordion-menu-internal label {
    cursor: pointer;
}

.cd-accordion-menu-internal label::before, .cd-accordion-menu-internal label::after {
    background-image: url(../img/plus.svg);
    background-repeat: no-repeat;
}

.cd-accordion-menu-internal label::before, .cd-accordion-menu-internal ul li label::before {
    /* arrow icon */
    right: 0px;
    background-position: 0 0px;
    -webkit-transform: translateY(-41%) rotate(-90deg);
    -moz-transform: translateY(-41%) rotate(-90deg);
    -ms-transform: translateY(-41%) rotate(-90deg);
    -o-transform: translateY(-41%) rotate(-90deg);
    transform: translateY(-41%) rotate(-90deg);
}

.cd-accordion-menu-internal ul li label::before {
    right: 0px;
}

.cd-accordion-menu-internal label::after {
    /* folder icons */
    left: 41px;
    background-position: -16px 0;
}

.cd-accordion-menu-internal a::after {
    /* image icon */
    left: 36px;
    background: url(../img/plus.svg) no-repeat -48px 0;
}

.cd-accordion-menu-internal input[type=checkbox]:checked + label::before {
    /* rotate arrow */
    -webkit-transform: translateY(-41%);
    -moz-transform: translateY(-41%);
    -ms-transform: translateY(-41%);
    -o-transform: translateY(-41%);
    transform: translateY(-41%);
}

.cd-accordion-menu-internal input[type=checkbox]:checked + label::after {
    /* show open folder icon if item is checked */
    background-position: -32px 0;
}

.cd-accordion-menu-internal input[type=checkbox]:checked + label + ul,
.cd-accordion-menu-internal input[type=checkbox]:checked + label:nth-of-type(n) + ul {
    /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
    /* show children when item is checked */
    display: block;
}

.cd-accordion-menu-internal ul label,
.cd-accordion-menu-internal ul a {
    padding-left: 20px;
}

.no-touch .cd-accordion-menu-internal ul label:hover, .no-touch
.cd-accordion-menu-internal ul a:hover {
    background: #3c3f45;
}

.cd-accordion-menu-internal > li:last-of-type > label,
.cd-accordion-menu-internal > li:last-of-type > a,
.cd-accordion-menu-internal > li > ul > li:last-of-type label,
.cd-accordion-menu-internal > li > ul > li:last-of-type a {
    box-shadow: none;
}

.cd-accordion-menu-internal ul label::before {
    right: 0px;
}

.cd-accordion-menu-internal ul label::after,
.cd-accordion-menu-internal ul a::after {
    left: 59px;
}

.cd-accordion-menu-internal ul ul label,
.cd-accordion-menu-internal ul ul a {
    padding-left: 0px;
}

.cd-accordion-menu-internal ul ul label::before {
    left: 54px;
}

.cd-accordion-menu-internal ul ul label::after,
.cd-accordion-menu-internal ul ul a::after {
    left: 77px;
}

.cd-accordion-menu-internal ul ul ul label,
.cd-accordion-menu-internal ul ul ul a {
    padding-left: 30px;
}

.cd-accordion-menu-internal ul ul ul label::before {
    left: 72px;
}

.cd-accordion-menu-internal ul ul ul label::after,
.cd-accordion-menu-internal ul ul ul a::after {
    left: 95px;
}

.cd-accordion-menu-internal li ul label {
    padding: 0px 11px 11px 0px;
}

.cd-accordion-menu-internal label {
    padding: 7px 11px 5px 0px;
}

.cd-accordion-menu-internal li ul li a {
    padding: 0px 10px 10px 0px;
    font-size: 0.9em;
    line-height: 1.25em;
}

@media only screen and (min-width: 600px) {

    .cd-accordion-menu-internal li ul li {
        margin: 0;
        border: none;
    }

    .cd-accordion-menu-internal li ul li ul li a {
        padding: 0px 10px 10px 0px;
    }

    .cd-accordion-menu-internal li ul li ul {
        padding: 0px 0px 0px 10px;
    }

    .cd-accordion-menu-internal label::after {
        left: 53px;
    }

    .cd-accordion-menu-internal ul label,
    .cd-accordion-menu-internal ul a {
        padding-left: 25px;
    }

    .cd-accordion-menu-internal ul label::before {
        right: 0px;
    }

    .cd-accordion-menu-internal ul label::after,
    .cd-accordion-menu-internal ul a::after {
        left: 77px;
    }

    .cd-accordion-menu-internal ul ul label,
    .cd-accordion-menu-internal ul ul a {
        padding-left: 0px;
    }

    .cd-accordion-menu-internal ul ul label::before {
        left: 72px;
    }

    .cd-accordion-menu-internal ul ul label::after,
    .cd-accordion-menu-internal ul ul a::after {
        left: 101px;
    }

    .cd-accordion-menu-internal ul ul ul label,
    .cd-accordion-menu-internal ul ul ul a {
        padding-left: 154px;
    }

    .cd-accordion-menu-internal ul ul ul label::before {
        left: 96px;
    }

    .cd-accordion-menu-internal ul ul ul label::after,
    .cd-accordion-menu-internal ul ul ul a::after {
        left: 125px;
    }

}

.cd-accordion-menu-internal.animated label::before {
    /* this class is used if you're using jquery to animate the accordion */
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;

}

/* BURGER NAV */

.burgerNav {
    margin-top: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
    background: color(primary);
    padding: 8px;
    border-radius: 10px;
}

#navIcon {
    overflow: hidden;
    width: 30px;
    height: 30px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#navIcon span {
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    background: color(white);
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#navIcon span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
}

#navIcon span:nth-child(odd) {
    left: 0px;
    border-radius: 9px 0 0 9px;
    margin-left: 0.5px;
}

#navIcon span:nth-child(1), #navIcon span:nth-child(2) {
    top: 0px;
}

#navIcon span:nth-child(3), #navIcon span:nth-child(4) {
    top: 11px;
}

#navIcon span:nth-child(5), #navIcon span:nth-child(6) {
    top: 22px;
}



.theLogo img, .theLogoSideNav img {
    margin-top: 0px;
    width: 165px;
    height: auto;
}

/* SIDE NAV */
.sidenav {
    height: 100%;
    position: fixed;
    z-index: 1998;
    top: 0;
    right: 0;
    overflow-x: hidden;
    display: none;
}

.sidenav a {
    text-decoration: none;
    display: block;
    font-size: 1em;
    overflow: hidden;
}

.sidenav .closebtn {
    width: 24px;
    height: 24px;
    color: color(white);
}

.sideNavHeader {
    
    padding: 16px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.theLogoSideNav {
    display: block;
}

/* DISPLAY AS TABLE (VERITCAL ALIGN) */
.tableOuter {
    display: table;
    width: 100%;
    height: 100%;
}

.tableInner {
    display: table-cell;
    vertical-align: middle;
}

iframe {
    display: block;
    width: 100%;
}

.iframeHolder {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.iframeHolder iframe,
.iframeHolder object,
.iframeHolder embed {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
}

/* MAIN NAV */

.navSplit {
    margin-right: 2px;
    opacity: 0;
    transition: all 0.5s ease;
}

.navPush {
    width: 100%;
    //height: 80px;
}

#mainMenu {
    margin: 0px 0px 0px 0px;
    padding: 0;
    display: none !important;
}

#headerHolder {
    position: fixed;
    background: #fff;
    width: 100%;
    top: 0;
    z-index: 100000;
}

#headerNav {
    position: relative;
    margin: 0 auto;
    top: 0;
    line-height: 1;
    display: none;
}

#headerNav ul, #headerNav ul li, #headerNav ul li a {
    position: relative;
    line-height: 1em;
    text-decoration: none;
    //text-transform: capitalize;
}

#headerNav li:hover .navSplit {
    opacity: 1;
}

#headerNav, #headerNav ul, #headerNav ul li, #headerNav ul li a {
    border: 0;
    list-style: none;
    line-height: 1;
    display: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#headerNav:after, #headerNav > ul:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

#headerNav ul li {
    float: left;
}

#headerNav ul li ul li {
    width: 280px;
}

#headerNav ul li a {
    padding: 0px 24px 4px 0px;
    text-align: center;
    //text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
}

#headerNav > ul > li:hover > a, #headerNav > ul > li.active > a {
    background-color: #4661AD;
    color: #fff;
}

#headerNav ul ul, #headerNav ul ul ul {
    position: absolute;
    z-index: 1000000;
    left: -9999px;
    padding: 0;
}

#headerNav li:hover > ul {
    left: 0px;
}

#headerNav ul ul li:hover > ul {
    left: 280px;
    top: 0;
}


#headerNav ul ul li, #headerNav ul ul ul li {
    max-height: 0;
    -webkit-transition: max-height 0.5s ease 0s;
    -moz-transition: max-height 0.5s ease 0s;
    -ms-transition: max-height 0.5s ease 0s;
    -o-transition: max-height 0.5s ease 0s;
    transition: max-height 0.5s ease 0s;
}

#headerNav ul li:hover > ul > li, #headerNav ul ul li:hover > ul > li {
    max-height: 60px;
}

#headerNav ul ul li a {
    padding: 12px 20px;
    min-width: 220px;
    background-color: color(secondary);
    text-decoration: none;
    color: color(white);
    text-align: left;
}

#headerNav ul ul li:hover > a, #headerNav ul ul li a:hover {
    background-color: color(purple);
    color: color(white);
}

.menuArrow {
    height: 15px;
    width: 100%;
    background: none !important;
}

.menuArrow img {
    margin-left: 10px;
}

.desktopNav {
    position: absolute;
    right: 75px;
    top: 31px;
}

/* MAIN SLIDER */

.mainHeader, .mainHeader .slick-slide, .innerContent, .textContent, .slideVideo, .slideImage, .slideCoverImage {
    height: 260px;
}

.mainHeader {
    width: 100%;
}

.innerContent {
    position: relative;
    width: 100%;
}

.textContent {
    position: absolute;
    z-index: 3;
    width: 100%;
}

.slideVideo, .slideCoverImage {
    position: absolute;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    background-size: cover;
}

.slideVideo video {
    width: 100%;
    height: auto;

}

.slideImage {
    position: absolute;
    z-index: 2;
    width: 100%;
    background-position: center;
    background-size: cover;
}

.sliderText {
    //text-transform: uppercase;
    font-size: 1.5em;
    text-shadow: 2px 2px 5px #333;
    color: #fff;
    width: 82%;
    margin-left: 30px;
    line-height: 1.2em;
}

.sliderOpacity {
    opacity: 0.8;
}

.opacity-50{
    opacity: 0.5;
}

.bottomSliderContent {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.bottomSliderText, .bottomSliderColor, .bottomSliderImage {
    position: absolute;
    width: 100%;
    height: 200px;
}

.bottomSliderTextButton {
    width: 25%;
}

.bottomSliderImage {
    background-size: cover;
    background-position: center;
}

.bottomSliderText {
    z-index: 3
}

.bottomSliderColor {
    z-index: 2
}

.bottomSliderImage {
    z-index: 1
}

/* THREE LINKS */
.threeLinksIcon img {
    width: 60px;
    margin-right: 20px;
}

.threeLinksIcon img, .threeLinksIcon h3 {
    display: inline-block;
}

/* INNER FORM */
.innerForm {
    margin-top: 20px;
    padding: 20px;
    color: #fff;
}

.hbspt-form label {
    float: left;
    width: 100%;
    padding-top: 5px;
}

.hbspt-form .input {
    float: left;
    width: 100%;
    color: #3d3d3e;
}

.hbspt-form .input:after {
    clear: both;
}

.hbspt-form .input input, .hbspt-form .input textarea {
    width: 100%;
}

.hbspt-form .input textarea {
    height: 100px;
}

.hbspt-form .hs-error-msgs {
    list-style: none;
    text-align: center;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.hbspt-form .hs-error-msgs label {
    float: none;
    width: 100% !important;
}

.hbspt-form .input {
    margin-bottom: 20px;
}

.hs-button {
    background: #fff;
    padding: 9px 10px 5px 10px;
    //text-transform: uppercase;
    font-weight: 700;
    color: #3d3d3e;
}

.hs_submit {
    width: 100%;
    text-align: right;
}

.hs_submit {
    clear: both;
}

/* OUR PRODUCTS */

.productButtons .theButtons {
    width: 47%;
    margin-bottom: 20px;
    height: 70px;
    line-height: 1.2em;
}

.productLinkImageContainer {
    height: 186px;
    width: 100%;
    overflow: hidden;
}

.productLinkImage {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    transition: all .5s;
}

/* TESTIMONIALS */
.caseStudiesImage {
    background-size: cover;
    background-position: center;
    padding-bottom: 210px;
}

/*.caseStudiesText {height: 210px}*/
.caseStudiesText .padding30 {
    padding: 30px 0px;
}

.caseStudiesColor {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
}

.theDots {
    height: 16px;
    width: 16px;
    border-radius: 40px;
    display: inline-block;
}

.theDots:hover {
    cursor: pointer;
}

/* CONTACT */
.contactWidth {
    width: 95%;
    margin: 0 auto;
}

.contactWidth .theButtons {
    width: 47%;
    float: left;
    height: 50px;
    line-height: 1.2em;
}

/* INNER PAGES */
.sideNav {
    display: none;
}

.innerPageContent a {
    text-decoration: underline;
    color: #3d3d3e;
}

.rightContent {
    text-align: left;
    padding-left: 0px;
}

.breadcrumbSplit {
    font-size: 1em;
    margin: -6px 10px 0px 10px;
}

.innerH1 {
    position: relative;
    z-index: 1;
    display: inline;
    padding-right: 5px;
    line-height: 1em;
}

.innerImage img {
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    /*border-radius: 8px;*/
}

.innerSideNav {
    margin: 0;
    padding: 0;
    list-style: none;
}

.subLink {
    margin: 0;
    padding: 0;
    list-style: none;
}

.intrinsic-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1) !important;
    -webkit-transform: scale(1) !important;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}

.videoWrapper {
    position: relative;
    padding-bottom: 185px;
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.contactMaps iframe {
    width: 100%;
    height: 350px;
}

.innerPageContent img {
    max-width: 100%;
}

.contactContent .paddingLeft30 {
    padding-left: 0;
}

.panel {
    padding: 0px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    border: none;
    box-shadow: none;
}

.innerHeader {
    min-height: 200px;
    background-size: cover;
    width: 100%;
    position: relative;
}

.clearfix {
    display: block;
}

.innerHeading {
    max-width: 80%; /*max-height: 140px;*/
}

.innerHeading .padding30, .innerContentHeading .padding30 {
    padding: 30px 20px;
}

.innerContentHeading { /*height: 145px;*/
}

.innerHeader .headerIcons {
    display: none;
}

.innerSideBarContainer {
    position: relative;
    right: 0;
    float: right;
    width: 100%;
}

.innerSideBar {
    min-height: 120px;
}

.innerSideBar:hover {
    cursor: pointer;
}

.innerSideBar .smallTitle, .innerSideBar .mainImage .smallTitle {
    margin-top: 10px;
}

.panelText .smallTitle {
    position: absolute;
    z-index: 1;
    padding-right: 60px;
    opacity: 1;
}

.panelText .learnMore {
    position: absolute;
    z-index: 2;
    opacity: 0;
}

.sideBanner {
    min-height: 150px;
}

.rightColumn {
    padding-top: 61px;
    margin-bottom: 24px;
    display: none;
}

/* FOOTER */
.footerLines {
    height: 140px;
}

.footer {
    font-size: 0.8em;
}

.footerLogo img {
    height: 37px;
    margin-top: -6px;
}

.footerIcons {
    display: inline-block;
}

.socialIcons {
    width: 20px;
    height: 40px;
    padding-top: 10px;
    font-size: 20px;
    margin-top: 6px;
}

.sidenav .socialIcons {

    padding-top: 0px;
    margin-top: 0px;
}

.footer a {
    color: inherit;
    text-decoration: underline;
}

.socialLinks {
    text-decoration: none !important;
}

.footer a:hover {
    /*color: #f4313f;*/
    text-decoration: none;
}

/* TINY PHONES */
@media only screen
and (max-device-width: 320px) {

    .col-xs-11 {
        width: 89.666667%;
    }

}

/* PHONES */
@media only screen
and (max-device-width: 666px) {

    .testimonialImage {
        padding-bottom: 260px;
    }

    #searchFormMobile #search {
        width: 240px;
    }

    #contact .buttons {
        margin: 0px 0px 15px 0px;
    }

    .mobileBottomPadding20 {
        padding-bottom: 20px;
    }

    .footer .padding30 {
        padding: 15px 5px;
    }

    .mainHeader, .mainHeader .slick-slide, .innerContent, .textContent, .slideVideo, .slideImage, .slideCoverImage {
        height: 300px;
    }
}

/* IPAD AND ABOVE */

@media only screen
and (min-device-width: 768px) {



    #aubinLines {
        background-position: -2035px 0px;
    }

    .marginLeftRight20 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mainHeader, .mainHeader .slick-slide, .innerContent, .textContent, .slideVideo, .slideImage, .slideCoverImage {
        height: 415px;
    }

    .sliderText {
        font-size: 2.8em;
        width: 75%;
        line-height: 1em;
    }



    .videoWrapper {
        padding-bottom: 390px;
    }

    .caseStudiesImage {
        padding-bottom: 415px;
    }

    .caseStudiesText .padding30 {
        padding: 30px
    }

    .productButtons .theButtons {
        width: 30%;
        margin-bottom: 20px;
        height: 51px;
        line-height: 1.2em;
    }

    .contactWidth {
        width: 100%;
    }

    .contactWidth .theButtons {
        width: 30%;
        float: none;
    }

    .no-gutters {
        margin-right: -10px;
        margin-left: -10px;
    }

    .contactContent .paddingLeft30 {
        padding-left: 30px !important;
    }

}

@media only screen
and (min-device-width: 1024px) {

    .videoWrapper {
        padding-bottom: 510px;
    }

    #aubinLines {
        background-position: -1845px 8px;
    }



    .rightContent {
        text-align: right;
        padding-left: 40px;
    }

    .mobileArrowPrev {
        margin-left: -29px;
        position: absolute;
        top: 36%;
    }

    .mobileArrowNext {
        right: -29px;
        position: absolute;
        top: 36%;
    }

}

@media only screen
and (min-device-width: 1025px) {

    .rightColumn {
        display: block;
    }

    #aubinLines {
        background-position: -1730px 3px;
    }

    .navPush {
        //height: 80px;
    }

    .navbar-default {
        //height: 80px;
    }

    .marginTop124 {
        margin-top: 80px;
    }

    .mobileArrowPrev, .mobileArrowNext {
        display: none;
    }

}

/* DESKTOP */
@media (min-width: 1366px) {

    #aubinLines {
        background-position: -1730px 59px;
    }

    .navPush {
        height: 124px;
    }

    .navbar-default {
        //height: 102px;
    }

    .marginTop124 {
        margin-top: 124px;
    }

    .mainHeader, .mainHeader .slick-slide, .innerContent, .textContent, .slideVideo, .slideImage, .slideCoverImage {
        height: 500px;
    }

    .sideNav {
        display: block;
    }

    .burgerNav, .sidenav {
        display: none;
    }

    #headerNav, #headerNav ul, #headerNav ul li, #headerNav ul li a {
        display: block;
    }

    #mainMenu {
        display: block !important;
    }

    #aubinLines {
        background-position: -1600px 59px;
    }

    .container {
        width: 1250px;
        //padding: 0;
    }

    .productButtons .theButtons {
        width: 15%;
    }

    .marginRight20Desktop {
        margin-right: 20px;
    }

    .productLinks:hover .productLinkImage, .bottomSliderContent:hover .bottomSliderImage:not(.no-hover),
    .productLinks:focus .productLinkImage {
        transform: scale(1.2);
    }

    .productLinks:hover .whiteBg, .bottomSliderContent:hover .whiteBg {
        background: #7c7d81;
        color: #fff;
    }

    .arrowPrev, .arrowNext {
        display: block;
    }



    .borderLine {
        margin-top: -13px;
    }

    .breadcrumbSplit {
        font-size: 1.5em;
        margin: -2px 10px 0px 10px;
    }

    .innerPageContent .videoWrapper {
        padding-bottom: 362px;
    }


}

@media (min-width: 1680px) {
    #aubinLines {
        background-position: -1265px 59px;
    }

    .container {
        width: 1400px;
    }

    .sliderText {
        width: 40%;
    }
}

@media (min-width: 2000px) {
    #aubinLines {
        background-position: -970px 59px;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi) {

    .mainHeader, .mainHeader .slick-slide, .innerContent, .textContent, .slideVideo, .slideImage, .slideCoverImage {
        height: 340px;
    }

}

@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: portrait) {
    .mainHeader, .mainHeader .slick-slide, .innerContent, .textContent, .slideVideo, .slideImage, .slideCoverImage {
        height: 259px;
    }

    .sliderText {
        font-size: 2em;
    }
}

.main_content h3 {
    //text-transform: uppercase;
    margin: 25px 0px 10px;
    display: block;
    padding: 10px 0px;
    font-weight: 600;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #bcbec0;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #bcbec0;
    color: #3d3d3e;
}

.rightContent .sidebar-button-container{
    width: 100%;
    text-align: center;
    margin-top: 40px;
}
.sidebar-button-container a.sidebar-button{
    text-decoration: none;
    color:white;
    background-color: color(purple);
    padding: 10px 20px 10px 20px;
    font-size: 24px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.rightContent .sidebar-item{
    marigin-bottom:10px;
}

table, table tr{
    width: 100%;
}
table, table tr, table td{
    border: solid 1px black;
}
table td{
    padding: 5px;
}

.leaflet-container{
    width: 100% !important;
    pointer-events: none;
}

.panel--control {
    color: #ffffff;
    background: color(secondary);
    width: 100%;
}

.panel--control ul {
    float: right;
    margin: 10px;
}

.panel--control li {
    display: inline-block;
    margin-left: 16px;
}

.w-100{
    width: 100% !important;
}

a.btn{
    color: white;
    text-decoration: none;
}

.leaflet-control-attribution
{
    display: none;
}

.font-w-400
{
    font-weight: 400;
}

.font-bold
{
    font-weight: bold;
}

.btn.btn-white
{
    background: #fff;
    color: #7c7d81;
    text-align: center;
    //text-transform: uppercase;
}

.btn.btn-grey
{
    background: #f4f5f7;
    color: #7c7d81;
    text-align: center;
    box-shadow: none;
    border: none;
    //text-transform: uppercase;
}

.btn.btn-white:hover, .btn.btn-grey:hover
{
    color: #fff;
    background: #7c7d81;
    text-decoration: none;
}

.max-w-prose {

    max-width: 65ch;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.innerPageContent ul > * + * {
    margin-top: 0.75rem;
}

.text-base {
    font-size: 16px !important;
    line-height: 150%;
}

.text-sm {
    font-size: 14px !important;
    line-height: 150%;
}