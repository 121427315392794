.LogoBlock {

    .LogoBlock__Heading {

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        column-gap: 0.5rem;
        padding-bottom: 1.5rem;

        .LogoBlock__Headline {

        }
    }

    .LogoBlock__Container {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        column-gap: 1.5rem;
        row-gap: 1.5rem;
        @include above(break(m)){ column-gap: 2rem; }
        @include above(break(l)){ column-gap: 3rem; }


        .LogoBlock__Logo {

            display: flex;
            justify-content: center;

            width: 8rem;
            @include above(break(l)){ width: 12rem; }


            .LogoBlock__Logo__Image {

                width: 100%;
                height: auto;
                max-height: 4rem;
                @include above(break(m)){ max-height: 5rem; }
                @include above(break(l)){ max-height: 6rem; }

            }
        }
    }
}