/* ==========================================================================
	 Video
	 ========================================================================== */

.Video {
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 30px;
		height: 0;
		margin-bottom: 40px;

	iframe,
	object,
	embed {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
}
