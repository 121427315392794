/*

	 ========================================================================== */

.BeforeAfterBlock {
    ul {
        padding-left: 0 !important;
    }

}
