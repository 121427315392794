.BenefitBlock {

    .BenefitBlock__List {

        .BenefitItem {

            .BenefitItem__Icon {
                
                flex-shrink: 0;
                width: 24px;
                height: 24px;
                color: #00428c;
                padding-top: 1px;
            }

            .BenefitItem__Text {
                
                margin-left: 8px;
                line-height: 150%;
            }
        }
    }
}