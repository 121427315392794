// ==========================================================================
// Custom resets
// ==========================================================================

button {
	border: 0;
	background: none;
	padding: 0;

	&:focus {
		outline: none;
	}
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
