/* ==========================================================================
	 Display (< Utilities < Tools)
	 ========================================================================== */

// hide

.display--none {
	display: none;
}

// on mobile only
.display--mobile {
	@include above(break(m)obile) {
		display: none;
	}
}

// on tablet only
.display--tablet {
	@include above($break-tablet) {
		display: none;
	}
}

// on desktop only
.display--desktop {
	@include below($break-tablet) {
		display: none;
	}
}
