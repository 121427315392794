/* ==========================================================================
	 Link
	 ========================================================================== */

a,
.Link {
	color: color(primary);
	&,
	&:visited,
	&:focus,
	&:hover {
		text-decoration: none;
	}

	&:hover {
		color: darken(color(primary), 5%);
	}

	&.Link--wrapper {
		color: color(primary);
	}
}
