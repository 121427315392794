html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
	margin: 0;
	line-height: inherit;
	background: color("white");
}
.antialiased {
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}
#wrapper {
	overflow: hidden;
	box-shadow: $shadow-ui;
}

body.debug:after {
		font-size: font-size(-1);
		line-height: 1;
		content: 'break:0';
		position: fixed;
		top: 0;
		color: color(white);
		background: rgba(color(secondary), 0.9);
		z-index: 9999;
		border-radius: 0 0 5px 0;
		@include space-inset(xs);

		@include above(break(xs)) {
				content: 'break:xs';
		}

		@include above(break(s)) {
				content: 'break:s';
		}

		@include above(break(m)) {
				content: 'break:m';
		}

		@include above(break(l)) {
				content: 'break:l';
		}

		@include above(break(xl)) {
				content: 'break:xl';
		}

		@include above(break(2xl)) {
				content: 'break:2xl';
		}
}

body.cke_editable {
		&:after {
			display:none;
		}
}
